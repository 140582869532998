// extracted by mini-css-extract-plugin
export var breakpoints = "'../../components/breakpoints.css'";
export var tabletUp = "(min-width: 768px)";
export var phoneL = "undefined";
export var colors = "'../../components/palette.css'";
export var grey1 = "#ddd";
export var grey2 = "#b6a99b";
export var grey3 = "#999";
export var grey5 = "#333";
export var white = "#fff";
export var hPaddingDesktop = "40px";
export var hPaddingDesktopNegative = "-40px";
export var hPaddingMobile = "25px";
export var hPaddingNegativeMobile = "-25px";
export var post = "blog-post-module--post--AK01W";
export var postIntro = "blog-post-module--postIntro--r3gEs";
export var postTitle = "blog-post-module--postTitle--Ds9si";
export var postHeadline = "blog-post-module--postHeadline--Poztj";
export var postBody = "blog-post-module--postBody---6MVy";
export var datePublished = "blog-post-module--datePublished--QHzVo";
export var postNavigation = "blog-post-module--postNavigation--AdT4y";